

















import { Watch, Component, Prop } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import InputWithValidation from './InputWithValidation'
import { extend } from 'vee-validate'

@Component
export default class ZipCodeCheck extends InputWithValidation {
  //#region [Props]
  @Prop({ type: String, required: true }) public readonly label!: string
  @Prop({ type: String, required: false }) public readonly name?: string
  @Prop({ type: Boolean, default: false }) public readonly required!: boolean
  @Prop({ type: Boolean, default: true }) public readonly checkZipCode!: boolean
  @Prop(String) public readonly value!: string | null
  //#endregion

  //#region [Data]
  public fieldId: string = this.name ? this.name : idGenerator.getId()
  public loading: boolean = false
  public zipCode: string | null = this.value
  //#endregion

  //#region [Watch]
  @Watch('value')
  public onValueChanged(newValue: string) {
    this.zipCode = newValue
  }

  @Watch('zipCode')
  public async onZipCodeChanged(newZipCode: string) {
    this.$nextTick(async () => {
      const valid = await this.$validatorObs.validate()
      this.$emit('input', newZipCode)
      this.notifyValidation(valid)
    })
  }
  //#endregion

  //#region [Methods]
  public mounted() {
    extend('zip-code-check', {
      validate: (value: string) => {
        if (!value || value.length !== 4) {
          return Promise.resolve({ valid: true })
        }

        this.loading = true
        return this.$addressRepository
          .get(`/zip-codes/${value}/validation`, {
            cache: {
              ignoreCache: false,
            },
          })
          .then(
            (response) => {
              this.notifyValidation(response.data.isValid)
              this.loading = false
              if (!response.data.isValid) {
                this.$emit('error')
              }
              return { valid: response.data.isValid }
            },
            (error) => {
              this.notifyValidation(false)
              this.$emit('error')
              this.loading = false
              return { valid: false }
            },
          )
      },
      message: this.$t('common.address.zipCodeNotExist') as string,
    })
  }
  //#endregion
}
