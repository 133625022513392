export enum CompanyType {
  asbl = 'asbl',
  sa = 'sa',
  sarl = 'sarl',
  sc = 'sc',
  sca = 'sca',
  scris = 'scris',
  scrl = 'scrl',
  scs = 'scs',
  snc = 'snc',
  sprl = 'sprl',
  sprlu = 'sprlu',
  physicalPerson = 'physicalPerson',
  other = 'other'
}

export enum PublicAuthorityType {
  municipalities = 'municipalities',
  cpas = 'cpas',
  approvedSocialHousingCompany = 'approvedSocialHousingCompany',
  spw = 'spw',
  other = 'other'
}
