


































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ContactBaseInfo } from '@/models'
import ContactTypeSelector from '@/components/inputs/contact/ContactTypeSelector.vue'
import { CompanyType, PublicAuthorityType } from '@/models/common/contact'

@Component({
  components: {
    ContactTypeSelector,
  },
})
export default class ContactBaseForm extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: true }) public readonly showBirthdate!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly showNationalId!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly showCustomerRef!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly showBankInformation!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly lockContactType!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly isBirthDateRequired!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly withContactType!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly isVatRequired!: boolean
  @Prop({ type: String, required: false}) public readonly vatTooltip!: string
  @Prop({ type: Object, required: true }) public readonly value!: ContactBaseInfo
  //#endregion

  //#region [Computed]
  public get bicVisible(): boolean {
    const bicVisible = (
      this.value.bankAccountIban !== null &&
      this.value.bankAccountIban.length > 1 &&
      !this.value.bankAccountIban.toLowerCase().startsWith('be')
    )

    if (!bicVisible) {
      this.value.bankAccountBic = null
    }
    return bicVisible
  }

  public get companyTypes(): Array<{ text: string; value: string; disable?: boolean }> {
    const list: Array<{ text: string; value: string; disable?: boolean }> = []

    if (this.value.contactType === 'freelanceOrCompany') {
      for (const type of Object.keys(CompanyType)) {
        list.push({ value: type, text: this.$t(`common.contact.companyType.${type}`) as string })
      }
    }

    if (this.value.contactType === 'publicAuthority') {
      for (const type of Object.keys(PublicAuthorityType)) {
        list.push({ value: type, text: this.$t(`common.contact.publicAuthorityType.${type}`) as string })
      }
    }

    return list
  }
  //#endregion

  public mounted(): void {
    if (!this.withContactType) {
      // auto set contact type to individual when contact type choice not available
      this.$emit('input', Object.assign({}, this.value, {contactType: 'individual'}))
    }
  }
}
