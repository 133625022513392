




import { Prop, Watch, Component } from 'vue-property-decorator';
import InputWithValidation from './InputWithValidation';
import { SubCity } from '@/models';

@Component
export default class SubCitySelector extends InputWithValidation {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string;
  @Prop(Boolean) public readonly required?: boolean | null;
  @Prop(Object) public readonly value!: SubCity | null;
  @Prop({ type: String, required: false }) public readonly zipCode?: string | null;
  @Prop({ type: Boolean, default: false }) public readonly oresAddressOnly!: boolean;
  //#endregion

  //#region [Data]
  public loading: boolean = false;
  public subCities: SubCity[] = [];
  public selectedSubCity: SubCity | null = this.value;
  //#endregion

  //#region [Computed]
  public get selection(): SubCity | null {
    return this.selectedSubCity;
  }
  public set selection(value: SubCity | null) {
    const subCity = value;
    if (subCity) {
      this.notifyValidation(true);
      this.$emit('input', subCity);
    } else {
      this.notifyValidation(false);
      this.$emit('input', null);
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('zipCode')
  public onZipCodeChanged() {
    this.$nextTick(() => {
      this.fetchSubCities();
    });
  }

  @Watch('value')
  public onValueChanged(newValue: SubCity | null) {
    this.selectedSubCity = newValue;
  }
  //#endregion

  //#region [Method]
  public async mounted() {
    if (this.zipCode !== null) {
      await this.fetchSubCities();
    }
  }
  public async fetchSubCities() {
    const zipCode = this.zipCode || '';
    if (zipCode === null || isNaN(+zipCode)) {
      this.subCities = [];
      this.selection = null;
      return;
    }

    // subcomponents shouldn't call this.clearApiErrors()
    this.loading = true;

    const response = await this.$addressRepository.get<SubCity[]>(`subcities?zipCode=${zipCode}`, {
      cache: {
        ignoreCache: false,
      },
    });
    const subCities = response.data.filter((sc) => !sc.isFictive);
    this.subCities = subCities;

    this.$emit('ZipCodeNotInOres', this.subCities.length === 0)

    const selectedId = this.selectedSubCity;

    if (selectedId && subCities.filter((s) => selectedId.georesSubcityId === s.georesSubcityId).length >= 1) {
      this.selection = selectedId;
    } else {
      if (this.value?.georesSubcityId === -1) {
        this.selection = this.value;
      } else {
        this.selection = null;
      }
    }

    if (this.subCities.length === 1) {
      this.selection = this.subCities[0]
    }

    this.loading = false;
  }

  public createDefaultSubCity(text: string | null): SubCity | null {
    if (!text) {
      return null;
    }

    return {
      georesSubcityId: -1,
      zipCode: this.zipCode || '-1',
      cityName: text,
      municipality: {
        georesMunicipalityId: -1,
        name: '',
      },
      isFictive: true,
      connectMyHomeActivated: false,
      connectMyHomeInformation: null,
      connectMyHomeActivationDate: null,
      gridOperators: null,
      gridManagers: {
        electricityManager: null,
        gasManager: null,
      },
      operationalOffices: {
        electricityOffice: null,
        gasOffice: null,
      },
    };
  }
  //#endregion
}
