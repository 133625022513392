




























































import { Component, Prop, Watch } from 'vue-property-decorator'
import ZipCodeCheck from '../address/ZipCodeCheck.vue'
import SubCitySelector from '../address/SubCitySelector.vue'
import StreetSubCitySelector from '../address/StreetSubCitySelector.vue'
import HouseNumberSelector from '../address/HouseNumberSelector.vue'
import InputWithValidation from '../address/InputWithValidation'
import { ContactAddress, StreetSubCity, SubCity } from '@/models'
import { Country } from '@/models/address-repository-api'

@Component({
  components: {
    ZipCodeCheck,
    SubCitySelector,
    StreetSubCitySelector,
    HouseNumberSelector,
  },
})
export default class AddressForm extends InputWithValidation {
  //#region [Property]
  @Prop(Object) public readonly value!: ContactAddress | null
  @Prop({ type: Boolean, required: false, default: true }) public readonly required!: boolean
  //#endregion

  //#region [Data]
  public countries: Country[] = []
  public temporaryZipCode: string | null = null
  public subCity: SubCity | null = null
  public streetSubCity: StreetSubCity | null = null
  public houseNumber: string | null = null
  public houseBox: string | null = null
  public country: string | null = null

  public isZipCodeValid: boolean = false
  public isSubCityValid: boolean = false
  public isStreetSubCityValid: boolean = false
  //#endregion

  //#region [Computed]
  get subCityId(): number | null {
    return this.subCity ? this.subCity.georesSubcityId : null
  }

  get streetSubCityId(): number | null {
    return this.streetSubCity ? this.streetSubCity.georesStreetSubcityId : null
  }

  get contactAddress(): ContactAddress | null {
    if (this.subCity === null) {
      return null
    }

    return {
      subCity: this.subCity,
      streetSubCity: this.streetSubCity,
      houseNumber: this.houseNumber,
      houseBox: this.houseBox,
      country: this.country,
    }
  }

  get addressString(): string | null {
    return this.subCity ? `${this.subCity.zipCode} ${this.subCity.cityName}, Belgique` : null
  }
  //#endregion

  //#region [Watch]
  @Watch('contactAddress', { deep: true })
  public onContactAddressChanged(val: ContactAddress | null, oldVal: ContactAddress | null): void {
    // Don't emit if no deeep value change
    if (val !== null && JSON.stringify(val) !== JSON.stringify(oldVal)) {
      this.$emit('input', val)
    }
  }

  @Watch('value')
  public onValueChanged(newVal: ContactAddress | null): void {
    if (!newVal || !newVal.subCity) {
      // reset all fields
      this.temporaryZipCode = null
      this.subCity = null
      this.streetSubCity = null
      this.houseNumber = null
      this.houseBox = null
      this.country = null
    } else {
      this.temporaryZipCode = newVal.subCity.zipCode
      this.subCity = newVal.subCity
      this.streetSubCity = newVal.streetSubCity
      this.houseNumber = newVal.houseNumber
      this.houseBox = newVal.houseBox
      this.country = newVal.country
    }
  }
  //#endregion

  //#region [Method]
  public emitZipCodeError(): void {
    this.$emit('zipCodeError')
  }

  public clearHouseNumber(): void {
    this.houseNumber = null
    this.houseBox = null
  }

  public onZipCodeValidation(isValid: boolean): void {
    this.isZipCodeValid = isValid && !!this.temporaryZipCode
  }

  public onSubCityValidation(isValid: boolean): void {
    this.isSubCityValid = isValid
  }

  public onStreetSubCityValidation(isValid: boolean): void {
    this.isStreetSubCityValid = isValid
  }

  public mounted() {
    this.$addressRepository
      .get<Country[]>('/countries', {
        cache: {
          ignoreCache: false,
        },
      })
      .then((response) => {
        const countries = response.data
        const belgiumIndex = countries.findIndex((x) => x.code === 'BE')
        const belgium: Country = countries.splice(belgiumIndex, 1)[0]
        this.countries = [belgium, ...countries]
      })
    this.onValueChanged(this.value)
  }
  //#endregion
}
