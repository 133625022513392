




























import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ContactType } from '@/models/common/contact'

@Component({
  name: 'contact-type-selector'
})
export default class ContactTypeSelector extends Vue {
  //#region [Property]
  @Prop({ type: String, required: false }) public readonly value!: ContactType | null
  @Prop({ type: Boolean, required: false, default: false }) public readonly lockContactType!: boolean
  //#endregion

  //#region [Computed]
  public get selection(): ContactType | null {
    return this.value
  }

  public set selection(val: ContactType | null) {
    this.change(val)
  }

  public get isPrivateDisabled(): boolean {
    return this.lockContactType && this.value !== null && this.value !== 'individual'
  }

  public get isProfessionalDisabled(): boolean {
    return this.lockContactType && this.value !== null && this.value === 'individual'
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: ContactType | null): ContactType | null {
    return value
  }
  //#endregion
}
