













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'send-confirmation' })
export default class SendConfirmation extends Vue {
  //#region [Props]
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: String, required: false }) public readonly email!: string
  @Prop({ type: Boolean, required: false, default: false }) public readonly multipleSend!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly sendWithDelay!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly feminizeTitle!: boolean
  //#endregion
}
